<template>
  <v-container
    id="formations"
    fluid
    tag="section"
  >
    <h1 class="font-weight-light mb-2 headline text-center">
      Formations en
      <v-select
        class="year-selector"
        v-model="annee"
        :items="years"
        @input="goToYear"
      />
    </h1>

    <base-material-card
      class="px-5 py-3"
      color="primary"
    >
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher une formation"
        single-line
        hide-details
      />
      <div class="py-2"></div>
      <template v-slot:heading>
        <v-tabs
          v-model="tabs"
          background-color="transparent"
          slider-color="white"
        >
          <v-tab
            class="mr-3"
            :to="'#confirmees'"
          >
            <v-icon class="mr-2">
              mdi-check-circle
            </v-icon>
            Confirmées ({{ nbFormations.confirmees }})
          </v-tab>
          <v-tab
            class="mr-3"
            :to="'#ouvertes'"
          >
            <v-icon class="mr-2">
              mdi-format-list-bulleted
            </v-icon>
            Ouvertes ({{ nbFormations.ouvertes }})
          </v-tab>
          <v-tab
            class="mr-3"
            :to="'#terminees'"
          >
            <v-icon class="mr-2">
              mdi-checkbox-multiple-marked
            </v-icon>
            Terminées ({{ nbFormations.terminees }})
          </v-tab>
          <v-tab
            class="mr-3"
            :to="'#fermees'"
          >
            <v-icon class="mr-2">
              mdi-close-box-multiple
            </v-icon>
            Fermées ({{ nbFormations.fermees }})
          </v-tab>
          <v-tab
            class="mr-3"
            :to="'#annulees'"
          >
            <v-icon class="mr-2">
              mdi-cancel
            </v-icon>
            Annulées ({{ nbFormations.annulees }})
          </v-tab>
        </v-tabs>
      </template>

      <v-tabs-items
        v-model="tabs"
        class="transparent"
      >
        <v-tab-item
          :key="1"
          value="confirmees"
        >
          <v-data-table
            class="table-formations"
            :headers="headers"
            :items="formations"
            :search="search"
            :sort-by="'dateDebut'"
            :sort-desc="false"
            @click:row="rowClick"
          >
             <template v-slot:[`item.intitule`]="{ item }">
              {{ item.intitule }}
              <v-tooltip
                bottom
                v-if="item.etat === 'ENCOURS'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1 blinking"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-radiobox-marked
                  </v-icon>
                </template>
                <span>En cours</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="item.etat === 'CONFIRME'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-check-circle
                  </v-icon>
                </template>
                <span>Confirmée</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.formateurs`]="{ item }">
              <span v-if="item.formateurs.length > 0">
                {{ item.formateurs.filter(f => f).map(f => f.prenom.charAt(0) + '. ' + f.nom).join(', ') }}
              </span>
              <span v-else>
                <i>Aucun</i>
              </span>
            </template>
            <template v-slot:[`item.adresse`]="{ item }">
              {{ item.adresse.ville }} {{ item.adresse.departement ? '(' + item.adresse.departement + ')' : '' }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <span v-html="displayType(item.type)" />
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item
          :key="2"
          value="ouvertes"
        >
          <v-data-table
            class="table-formations"
            :headers="headers"
            :items="formations"
            :search="search"
            :sort-by="'dateDebut'"
            :sort-desc="false"
            @click:row="rowClick"
          >
            <template v-slot:[`item.formateurs`]="{ item }">
              <span v-if="item.formateurs.length > 0">
                {{ item.formateurs.filter(f => f).map(f => f.prenom.charAt(0) + '. ' + f.nom).join(', ') }}
              </span>
              <span v-else>
                <i>Aucun</i>
              </span>
            </template>
            <template v-slot:[`item.adresse`]="{ item }">
              {{ item.adresse.ville }} {{ item.adresse.departement ? '(' + item.adresse.departement + ')' : '' }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <div v-html="displayType(item.type)"></div>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item
          :key="3"
          value="terminees"
        >
          <v-data-table
            class="table-formations"
            :headers="headers"
            :items="formations"
            :search="search"
            :sort-by="'dateFin'"
            :sort-desc="true"
            @click:row="rowClick"
          >
            <template v-slot:[`item.intitule`]="{ item }">
              {{ item.intitule }}
              <v-tooltip
                bottom
                v-if="item.etat === 'TERMINE' && item.nbFactures === 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1"
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span>Aucune facture associée</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.formateurs`]="{ item }">
              <span v-if="item.formateurs.length > 0">
                {{ item.formateurs.filter(f => f).map(f => f.prenom.charAt(0) + '. ' + f.nom).join(', ') }}
              </span>
              <span v-else>
                <i>Aucun</i>
              </span>
            </template>
            <template v-slot:[`item.adresse`]="{ item }">
              {{ item.adresse.ville }} {{ item.adresse.departement ? '(' + item.adresse.departement + ')' : '' }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <div v-html="displayType(item.type)"></div>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item
          :key="4"
          value="fermees"
        >
          <v-data-table
            class="table-formations"
            :headers="headers"
            :items="formations"
            :search="search"
            :sort-by="'dateDebut'"
            :sort-desc="false"
            @click:row="rowClick"
          >
            <template v-slot:[`item.intitule`]="{ item }">
              {{ item.intitule }}
            </template>
            <template v-slot:[`item.formateurs`]="{ item }">
              <span v-if="item.formateurs.length > 0">
                {{ item.formateurs.filter(f => f).map(f => f.prenom.charAt(0) + '. ' + f.nom).join(', ') }}
              </span>
              <span v-else>
                <i>Aucun</i>
              </span>
            </template>
            <template v-slot:[`item.adresse`]="{ item }">
              {{ item.adresse.ville }} {{ item.adresse.departement ? '(' + item.adresse.departement + ')' : '' }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <div v-html="displayType(item.type)"></div>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item
          :key="5"
          value="annulees"
        >
          <v-data-table
            class="table-formations"
            :headers="headers"
            :items="formations"
            :search="search"
            :sort-by="'dateDebut'"
            :sort-desc="false"
            @click:row="rowClick"
          >
            <template v-slot:[`item.intitule`]="{ item }">
              {{ item.intitule }}
            </template>
            <template v-slot:[`item.formateurs`]="{ item }">
              <span v-if="item.formateurs.length > 0">
                {{ item.formateurs.filter(f => f).map(f => f.prenom.charAt(0) + '. ' + f.nom).join(', ') }}
              </span>
              <span v-else>
                <i>Aucun</i>
              </span>
            </template>
            <template v-slot:[`item.adresse`]="{ item }">
              {{ item.adresse.ville }} {{ item.adresse.departement ? '(' + item.adresse.departement + ')' : '' }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <div v-html="displayType(item.type)"></div>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>

    <div
      class="d-flex justify-end mb-3"
    >
      <v-btn
        color="primary"
        x-large
        :to="{ name: 'CreerFormation'}"
      >
        <v-icon class="mr-2">mdi-plus-circle</v-icon>
        Créer une nouvelle session de formation
    </v-btn>
    </div>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    name: 'Formations',

    data() {
      return {
        search: '',
        annee: '',
        years: [],
        tabs: 0,
        headers: [
          {
            sortable: true,
            text: 'Intitulé',
            value: 'intitule',
          },
          {
            sortable: false,
            text: 'Réf.',
            value: 'reference',
          },
          {
            sortable: false,
            text: 'Type',
            value: 'type',
          },
          {
            sortable: true,
            text: 'Date début',
            value: 'dateDebutFormatted',
          },
          {
            sortable: true,
            text: 'Date fin',
            value: 'dateFinFormatted',
          },
          {
            sortable: true,
            text: 'Formateur(s)',
            value: 'formateurs',
          },
          {
            sortable: false,
            text: 'Lieu',
            value: 'adresse',
          },
          {
            sortable: true,
            text: 'Stagiaires',
            value: 'nbStagiaires',
          },
        ],
        listOfEtats: ['enCours', 'confirmees', 'ouvertes', 'terminees', 'fermees', 'annulees'],
        formations: [],
        anneePremiereFormation: new Date().getFullYear(),
        nbFormations: {
          confirmees: 0,
          ouvertes: 0,
          terminees: 0,
          fermees: 0,
          annulees: 0,
        },
      }
    },

    created () {
      this.annee = this.$route.params.year;
      if ((!this.$route.hash) || (this.$route.hash && this.$route.hash.slice(1) === 'confirmees')) {
        this.getFormationsEnCoursAndConfirmees();
      } else if (this.$route.hash && this.listOfEtats.includes(this.$route.hash.slice(1))) {
        this.getFormationsFromYearAndEtat(this.$route.hash.slice(1));
      } else {
        this.setSnack({ text: 'Erreur lors de la récupération des formations', type: 'warn' });
        this.formations = [];
      }
      this.axios.get('/formations/anneePremiere')
        .then((res) => {
          if (res.data !== '') {
            this.anneePremiereFormation = res.data;
          }
          this.years = this.allBetweenYears(this.anneePremiereFormation, new Date().getFullYear());
        });

      this.axios.get(`/formations/enCours/annee/${this.annee}/count`)
        .then(res => {
          this.nbFormations.confirmees = res.data;
          return this.axios.get(`/formations/confirmees/annee/${this.annee}/count`);
        })
        .then(res => { this.nbFormations.confirmees += res.data });

      this.axios.get(`/formations/ouvertes/annee/${this.annee}/count`)
        .then(res => { this.nbFormations.ouvertes = res.data });

      this.axios.get(`/formations/terminees/annee/${this.annee}/count`)
        .then(res => { this.nbFormations.terminees = res.data });

      this.axios.get(`/formations/fermees/annee/${this.annee}/count`)
        .then(res => { this.nbFormations.fermees = res.data });

      this.axios.get(`/formations/annulees/annee/${this.annee}/count`)
        .then(res => { this.nbFormations.annulees = res.data });
    },

    methods: {

      rowClick(item, row) {
        this.$router.push({ name: 'FormationDetail', params: { id: item.id } });
      },

      goToYear() {
        if (this.annee !== this.$route.params.year) {
          this.$router.push({ name: 'FormationsAnnee', params: { year: this.annee }, hash: '#confirmees' });
        }
      },

      allBetweenYears(minYear, maxYear) {
        const res = [];
        let year = maxYear;
        while (minYear <= year) {
          res.push(String(year));
          year--;
        }
        return res;
      },

      getFormationsEnCoursAndConfirmees() {
        this.axios.get('/formations/enCours/annee/' + this.annee)
          .then((res) => {
            this.formations = [...res.data];
            this.formations.forEach(formation => {
              formation.dateDebutFormatted = new Date(formation.dateDebut).toLocaleString('fr-FR').substring(0, 10);
              formation.dateFinFormatted = new Date(formation.dateFin).toLocaleString('fr-FR').substring(0, 10);
              if (formation.distanciel) {
                formation.adresse = { ville: 'Visio' };
              }
            });
            return this.axios.get('/formations/confirmees/annee/' + this.annee)
          })
          .then((res) => {
            this.formations = this.formations.concat([...res.data]);
            this.formations.forEach(formation => {
              formation.dateDebutFormatted = new Date(formation.dateDebut).toLocaleString('fr-FR').substring(0, 10);
              formation.dateFinFormatted = new Date(formation.dateFin).toLocaleString('fr-FR').substring(0, 10);
              if (formation.distanciel) {
                formation.adresse = { ville: 'Visio' };
              }
            });
            return res;
          });
      },

      getFormationsFromYearAndEtat(etat) {
        this.axios.get('/formations/' + etat + '/annee/' + this.annee).then((res) => {
          this.formations = [...res.data];
          this.formations.forEach(formation => {
            formation.dateDebutFormatted = new Date(formation.dateDebut).toLocaleString('fr-FR').substring(0, 10);
            formation.dateFinFormatted = new Date(formation.dateFin).toLocaleString('fr-FR').substring(0, 10);
            if (formation.distanciel) {
              formation.adresse = { ville: 'Visio' };
            }
          });
        });
      },

      displayType(type) {
        switch (type) {
          case 'INTER':
            return '<span class="badge-chips-small badge-blue-outline">Inter</span>';
          case 'INTRA':
            return '<span class="badge-chips-small badge-yellow-outline">Intra</span>';
          case 'SSTRT':
            return '<span class="badge-chips-small badge-gray-outline">Sous-traitée</span>';
        }
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },

    watch: {
      $route(to, from) {
        if (this.$route.hash && this.$route.hash.slice(1) === 'confirmees') {
          this.getFormationsEnCoursAndConfirmees();
        } else if (this.$route.hash && this.listOfEtats.includes(this.$route.hash.slice(1))) {
          this.getFormationsFromYearAndEtat(this.$route.hash.slice(1));
        } else {
          this.setSnack({ text: 'Erreur lors de la récupération des formations', type: 'warn' });
          this.formations = [];
        }
      },
    },
  }
</script>

<style scoped>
  .table-formations >>> tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  .table-formations >>> tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  .table-formations >>> .badge-chips-small {
    white-space: nowrap;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    font-weight: 700;
  }

  .table-formations >>> .badge-blue-outline {
    border: solid 1px #4b70ff;
    color: #4b70ff;
  }

  .table-formations >>> .badge-yellow-outline {
    border: solid 1px #c95017;
    color: #c95017;
  }

  .table-formations >>> .badge-gray-outline {
    border: solid 1px #797b86;
    color: #797b86;
  }
  .year-selector {
    font-size: 1em;
    display: inline-block;
    margin-left: 0.1em;
    width: 3.6em;
  }

  .year-selector >>> .v-select__selection--comma {
    overflow: unset;
  }

  .table-formations >>> .blinking {
    animation: blinker 3s ease-out infinite;
  }

  .table-formations >>> .aucun-client {
    font-style: italic;
    color: rgb(180, 180, 180);
    font-size: 0.9em;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

</style>
